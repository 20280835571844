<template>
  <main>
    <section class="bg-light cameras">
      <div class="pt-5 container secondary">
        <div class="row">
          <div class="col-md-6 col-lg-3 bg-white py-4 px-2 tree-view">
            <ul v-for="first in firstSubGroup">
              <li :key="first.name">{{first.name}} </li>
              <ul v-for="second in first.subgroups">
                <li :key="second.name">{{second.name}}</li>
                <ul>
                  <draggable v-model="second.cameras" :list="second.cameras"  :group="{ name: 'camera', pull: 'clone', put: false}" :sort="false">
                    <template #item="{element}">
                      <li :key="element.name" class="cursor-move">
                        {{ element.camNo }} - {{ element.name }}
                      </li>
                    </template>
                  </draggable>
                </ul>
              </ul>
            </ul>
          </div>
          <div class="col-md-6 col-lg-9 mb-5 camera-view">
            <div class="row">
              <!-- <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <h4 class="mb-3">Layout #0 (Without API)</h4>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row" v-model="list5" group="camera" :animation="200" ghost-class="ghost-card" @change="list5.splice(length1)">
                      <template #item="{element}">
                        <div class="list-item cursor-move" data-toggle="tooltip" data-placement="right" :title="element" :class="{
                            'col-md-12': length1 == 1,
                            'col-md-6': length1 == 4,
                            'col-md-4': length1 == 9,
                            'col-md-3': length1 > 9,
                          }" :key="element.name">
                          {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLengthWA(1)">1</li>
                      <li class="list-group-item" role="button" @click="changeLengthWA(4)">4</li>
                      <li class="list-group-item" role="button" @click="changeLengthWA(9)">9</li>
                      <li class="list-group-item" role="button" @click="changeLengthWA(12)">12</li>
                    </ul>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-6 mt-4" v-for="layout in layouts" :key="layout.id">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #{{layout.id}}</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - {{layout.gridSize}}</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent" v-model="layout.cameras" :lists="layout.cameras" :group="{ name: 'camera', put: layout.cameras.length > layout.gridSize}" :sort="false" @add="permitEntry(layout.gridSize, layout.id, layout.cameras.length)" @end="finishCameraMove(layout.id)">
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" data-toggle="tooltip" data-placement="right" :title="element.camNo" :class="'div' + index" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #1</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 1</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-1" v-model="list1" :lists="list1" :group="{name: 'camera', put: allowPut(list1.length, 1) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <div class="card-footer">
                    <div class="dropdown">
                      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Layout
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <h6 class="dropdown-header">Choose a layout</h6>
                        <a class="dropdown-item" href="#">1</a>
                        <a class="dropdown-item" href="#">2</a>
                        <a class="dropdown-item" href="#">3</a>
                      </div>
                    </div>
                    <!-- <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul> -->
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #2</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 4</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-2" v-model="list2" :lists="list2" :group="{ name: 'camera', put: allowPut(list2.length, 4) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #3</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 9</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-3" v-model="list3" :lists="list3" :group="{ name: 'camera', put: allowPut(list3.length, 9) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #4</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 12</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-4" v-model="list4" :lists="list4" :group="{ name: 'camera', put: allowPut(list4.length, 12) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #5</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 25</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-5" v-model="list5" :lists="list5" :group="{ name: 'camera', put: allowPut(list5.length, 25) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #6</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 6</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-6" v-model="list6" :lists="list6" :group="{ name: 'camera', put: allowPut(list6.length, 6) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #7</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 8</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-7" v-model="list7" :lists="list7" :group="{ name: 'camera', put: allowPut(list7.length, 8) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #8</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 10</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-8" v-model="list8" :lists="list8" :group="{ name: 'camera', put: allowPut(list8.length, 10) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #9</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 13</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-9" v-model="list9" :lists="list9" :group="{ name: 'camera', put: allowPut(list9.length, 13  ) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #10</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 17</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-10" v-model="list10" :lists="list10" :group="{ name: 'camera', put: allowPut(list10.length, 17) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #11</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 6</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-11" v-model="list11" :lists="list11" :group="{ name: 'camera', put: allowPut(list11.length, 6) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #12</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 12</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-12" v-model="list12" :lists="list12" :group="{ name: 'camera', put: allowPut(list12.length, 12) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-8 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #13</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 24</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-13" v-model="list13" :lists="list13" :group="{ name: 'camera', put: allowPut(list13.length, 24) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #14</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 9</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-14" v-model="list14" :lists="list14" :group="{ name: 'camera', put: allowPut(list14.length, 9) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #15</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 9</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-15" v-model="list15" :lists="list15" :group="{ name: 'camera', put: allowPut(list15.length, 9) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #16</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 4</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-16" v-model="list16" :lists="list16" :group="{ name: 'camera', put: allowPut(list16.length, 4) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #17</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 7</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-17" v-model="list17" :lists="list17" :group="{ name: 'camera', put: allowPut(list17.length, 7) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #18</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 9</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-18" v-model="list18" :lists="list18" :group="{ name: 'camera', put: allowPut(list18.length, 9) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #19</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 13</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-19" v-model="list19" :lists="list19" :group="{ name: 'camera', put: allowPut(list19.length, 13) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Layout #20</h3>
                    <div class="card-options text-right">
                      <span class="">Grid Size - 21</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <draggable class="draggable-list row parent-20" v-model="list20" :lists="list20" :group="{ name: 'camera', put: allowPut(list20.length, 21) }" >
                      <template #item="{index, element}">
                        <div class="list-item cursor-move" :class="'div' +  (index+1)" data-toggle="tooltip" data-placement="right" :key="element.name">
                          {{ element.camNo }} - {{ element.name }}
                        </div>
                      </template>
                    </draggable>
                  </div>
                  <!-- <div class="card-footer">
                    <ul class="list-group list-group-horizontal-sm justify-content-center">
                      <li class="list-group-item" role="button" @click="changeLength(1, layout.id)">1</li>
                      <li class="list-group-item" role="button" @click="changeLength(4, layout.id)">4</li>
                      <li class="list-group-item" role="button" @click="changeLength(9, layout.id)">9</li>
                      <li class="list-group-item" role="button" @click="changeLength(12, layout.id)">12</li>
                    </ul>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import axios from 'axios';
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      length1: 1,
      length2: 1,
      list1: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }],
      list2: [{ name: "3456 Building 1 entrance", camNo: "Building 1" },{ name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list3: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, {name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list4: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list5: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list6: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list7: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list8: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list9: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list10: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list11: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list12: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list13: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list14: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list15: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list16: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list17: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list18: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list19: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      list20: [{ name: "3456 Building 1 entrance", camNo: "Building 1" }, { name: "3457 Building 1 lobby" , camNo: "Building 1" }],
      webUrl: 'https://cctv.digital-panda.co.uk/api/',
      data: {},
      firstSubGroup: {},
      secondSubGroup: {},
      thirdSubGroup: {},
      cameras: {},
      animate: true,
      layouts: {}
    };
  },
  created () {
    this.fetch()
    this.fetchLayouts()
  },
  methods: {
    fetch() {
      var endpoint = this.webUrl + 'cameras.php'
      axios.get(endpoint)
          .then(response => {
            this.firstSubGroup = response.data.subgroups
          }).catch(error => {
            console.log(error);
          })
    },
    fetchLayouts() {
      var endpoint = this.webUrl + 'layouts.php'
      axios.get(endpoint)
          .then(response => {
            this.layouts = response.data.layouts
          }).catch(error => {
            console.log(error);
          })
    },
    changeLengthWA(length) {
      this.length1 = length
      this.list5.splice(length)
    },
    changeLength(length, layoutId) {
      var endpoint = this.webUrl + 'layout/'
      axios.post(endpoint + layoutId, this.layout)
          .then(response => {
            console.log(response)
          }).catch(error => {
            console.log(error);
          })
    },
    permitEntry(gridSize, layoutId, cameraLength) {
      this.layouts.forEach(layout => {
        if(layout.id == layoutId) {
          if(cameraLength > gridSize) {
            layout.cameras.splice(gridSize)
            this.animate = false
            return true
          }
        }
        this.animate = true
        return false
      });
      
      //if(cameraLength > gridSize)
      
    },
    finishCameraMove(layoutId) {
      var endpoint = this.webUrl + 'layout/'
      var data = {}
      axios.post(endpoint + layoutId, this.layout)
          .then(response => {
            console.log(response)
          }).catch(error => {
            console.log(error);
          })
    },
    allowPut(listLength, gridSize) {
      if(listLength >= gridSize) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style scoped>
.cameras {
  height: 100%;
}
.cameras .card-body {
  height: auto;
  margin-top: -1px;
  min-height: 370px;
  display: grid;
  padding: 5px;
}
.card-list-group {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-left: -3px;
  margin-right: -3px;
}
.card-items-list {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: none;
  padding: 2px;
}
.draggable-list {
  color: rgb(160, 160, 160);
  margin: 0;
}
.draggable-list div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
  min-height: 100px;
  transition: all .3s ease;
  margin: 0;
  border: 1px solid;
}

.list-item {
  margin: 10px;
  cursor: move;
  font-size: 18px;
  background: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
  color: rgb(160, 160, 160);
  display: inline-block;
}
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.cursor-move {
  cursor: move;
}
.tree-view,
.camera-view {
  height: 100vh;
  overflow-y: auto;
}
.tree-view ul,
tree-view li {
  font-weight: bold;
}
.card-footer {
  text-align: right;
}
.dropdown-menu {
  right: 0;
}

.parent-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-1 .div1 { grid-area: 1 / 1 / 2 / 2; }

.parent-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-2 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-2 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-2 .div3 { grid-area: 2 / 1 / 3 / 2; }
.parent-2 .div4 { grid-area: 2 / 2 / 3 / 3; }

.parent-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-3 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-3 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-3 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-3 .div4 { grid-area: 2 / 1 / 3 / 2; }
.parent-3 .div5 { grid-area: 2 / 2 / 3 / 3; }
.parent-3 .div6 { grid-area: 2 / 3 / 3 / 4; }
.parent-3 .div7 { grid-area: 3 / 1 / 4 / 2; }
.parent-3 .div8 { grid-area: 3 / 2 / 4 / 3; }
.parent-3 .div9 { grid-area: 3 / 3 / 4 / 4; }

.parent-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-4 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-4 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-4 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-4 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-4 .div5 { grid-area: 2 / 1 / 3 / 2; }
.parent-4 .div6 { grid-area: 2 / 2 / 3 / 3; }
.parent-4 .div7 { grid-area: 2 / 3 / 3 / 4; }
.parent-4 .div8 { grid-area: 2 / 4 / 3 / 5; }
.parent-4 .div9 { grid-area: 3 / 1 / 4 / 2; }
.parent-4 .div10 { grid-area: 3 / 2 / 4 / 3; }
.parent-4 .div11 { grid-area: 3 / 3 / 4 / 4; }
.parent-4 .div12 { grid-area: 3 / 4 / 4 / 5; }
.parent-4 .div13 { grid-area: 4 / 1 / 5 / 2; }
.parent-4 .div14 { grid-area: 4 / 2 / 5 / 3; }
.parent-4 .div15 { grid-area: 4 / 3 / 5 / 4; }
.parent-4 .div16 { grid-area: 4 / 4 / 5 / 5; }

.parent-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.parent-5 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-5 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-5 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-5 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-5 .div5 { grid-area: 1 / 5 / 2 / 6; }
.parent-5 .div6 { grid-area: 2 / 1 / 3 / 2; }
.parent-5 .div7 { grid-area: 2 / 2 / 3 / 3; }
.parent-5 .div8 { grid-area: 2 / 3 / 3 / 4; }
.parent-5 .div9 { grid-area: 2 / 4 / 3 / 5; }
.parent-5 .div10 { grid-area: 2 / 5 / 3 / 6; }
.parent-5 .div11 { grid-area: 3 / 1 / 4 / 2; }
.parent-5 .div12 { grid-area: 3 / 2 / 4 / 3; }
.parent-5 .div13 { grid-area: 3 / 3 / 4 / 4; }
.parent-5 .div14 { grid-area: 3 / 4 / 4 / 5; }
.parent-5 .div15 { grid-area: 3 / 5 / 4 / 6; }
.parent-5 .div16 { grid-area: 4 / 1 / 5 / 2; }
.parent-5 .div17 { grid-area: 4 / 2 / 5 / 3; }
.parent-5 .div18 { grid-area: 4 / 3 / 5 / 4; }
.parent-5 .div19 { grid-area: 4 / 4 / 5 / 5; }
.parent-5 .div20 { grid-area: 4 / 5 / 5 / 6; }
.parent-5 .div21 { grid-area: 5 / 1 / 6 / 2; }
.parent-5 .div22 { grid-area: 5 / 2 / 6 / 3; }
.parent-5 .div23 { grid-area: 5 / 3 / 6 / 4; }
.parent-5 .div24 { grid-area: 5 / 4 / 6 / 5; }
.parent-5 .div25 { grid-area: 5 / 5 / 6 / 6; }

.parent-6 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}
.parent-6 .div1 { grid-area: 1 / 1 / 3 / 3; }
.parent-6 .div2 { grid-area: 1 / 3 / 2 / 4; }
.parent-6 .div3 { grid-area: 2 / 3 / 3 / 4; }
.parent-6 .div4 { grid-area: 3 / 3 / 4 / 4; }
.parent-6 .div5 { grid-area: 3 / 2 / 4 / 3; }
.parent-6 .div6 { grid-area: 3 / 1 / 4 / 2; }

.parent-7 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-7 .div1 { grid-area: 1 / 1 / 4 / 4; }
.parent-7 .div2 { grid-area: 1 / 4 / 2 / 5; }
.parent-7 .div3 { grid-area: 2 / 4 / 3 / 5; }
.parent-7 .div4 { grid-area: 3 / 4 / 4 / 5; }
.parent-7 .div5 { grid-area: 4 / 4 / 5 / 5; }
.parent-7 .div6 { grid-area: 4 / 3 / 5 / 4; }
.parent-7 .div7 { grid-area: 4 / 2 / 5 / 3; }
.parent-7 .div8 { grid-area: 4 / 1 / 5 / 2; }

.parent-8 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-8 .div1 { grid-area: 1 / 1 / 4 / 4; }
.parent-8 .div2 { grid-area: 1 / 1 / 5 / 5; }
.parent-8 .div3 { grid-area: 1 / 5 / 2 / 6; }
.parent-8 .div4 { grid-area: 2 / 5 / 3 / 6; }
.parent-8 .div5 { grid-area: 3 / 5 / 4 / 6; }
.parent-8 .div6 { grid-area: 4 / 5 / 5 / 6; }
.parent-8 .div7 { grid-area: 5 / 5 / 6 / 6; }
.parent-8 .div8 { grid-area: 5 / 4 / 6 / 5; }
.parent-8 .div9 { grid-area: 5 / 3 / 6 / 4; }
.parent-8 .div10 { grid-area: 5 / 2 / 6 / 3; }
.parent-8 .div11 { grid-area: 5 / 1 / 6 / 2; }

.parent-9 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-9 .div1 { grid-area: 2 / 2 / 4 / 4; }
.parent-9 .div2 { grid-area: 1 / 1 / 2 / 2; }
.parent-9 .div3 { grid-area: 1 / 2 / 2 / 3; }
.parent-9 .div4 { grid-area: 1 / 3 / 2 / 4; }
.parent-9 .div5 { grid-area: 1 / 4 / 2 / 5; }
.parent-9 .div6 { grid-area: 2 / 4 / 3 / 5; }
.parent-9 .div7 { grid-area: 3 / 4 / 4 / 5; }
.parent-9 .div8 { grid-area: 4 / 4 / 5 / 5; }
.parent-9 .div9 { grid-area: 4 / 3 / 5 / 4; }
.parent-9 .div10 { grid-area: 4 / 2 / 5 / 3; }
.parent-9 .div11 { grid-area: 4 / 1 / 5 / 2; }
.parent-9 .div12 { grid-area: 3 / 1 / 4 / 2; }
.parent-9 .div13 { grid-area: 2 / 1 / 3 / 2; }

.parent-10 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-10 .div1 { grid-area: 2 / 2 / 5 / 5; }
.parent-10 .div2 { grid-area: 1 / 1 / 2 / 2; }
.parent-10 .div3 { grid-area: 1 / 2 / 2 / 3; }
.parent-10 .div4 { grid-area: 1 / 3 / 2 / 4; }
.parent-10 .div5 { grid-area: 1 / 4 / 2 / 5; }
.parent-10 .div6 { grid-area: 1 / 5 / 2 / 6; }
.parent-10 .div7 { grid-area: 2 / 5 / 3 / 6; }
.parent-10 .div8 { grid-area: 3 / 5 / 4 / 6; }
.parent-10 .div9 { grid-area: 4 / 5 / 5 / 6; }
.parent-10 .div10 { grid-area: 5 / 5 / 6 / 6; }
.parent-10 .div11 { grid-area: 5 / 4 / 6 / 5; }
.parent-10 .div12 { grid-area: 5 / 3 / 6 / 4; }
.parent-10 .div13 { grid-area: 5 / 2 / 6 / 3; }
.parent-10 .div14 { grid-area: 5 / 1 / 6 / 2; }
.parent-10 .div15 { grid-area: 4 / 1 / 5 / 2; }
.parent-10 .div16 { grid-area: 3 / 1 / 4 / 2; }
.parent-10 .div17 { grid-area: 2 / 1 / 3 / 2; }

.parent-11 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-11 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-11 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-11 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-11 .div4 { grid-area: 2 / 1 / 3 / 2; }
.parent-11 .div5 { grid-area: 2 / 2 / 3 / 3; }
.parent-11 .div6 { grid-area: 2 / 3 / 3 / 4; }

.parent-12 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-12 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-12 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-12 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-12 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-12 .div5 { grid-area: 2 / 1 / 3 / 2; }
.parent-12 .div6 { grid-area: 2 / 2 / 3 / 3; }
.parent-12 .div7 { grid-area: 2 / 3 / 3 / 4; }
.parent-12 .div8 { grid-area: 2 / 4 / 3 / 5; }
.parent-12 .div9 { grid-area: 3 / 1 / 4 / 2; }
.parent-12 .div10 { grid-area: 3 / 2 / 4 / 3; }
.parent-12 .div11 { grid-area: 3 / 3 / 4 / 4; }
.parent-12 .div12 { grid-area: 3 / 4 / 4 / 5; }

.parent-13 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-13 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-13 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-13 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-13 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-13 .div5 { grid-area: 1 / 5 / 2 / 6; }
.parent-13 .div6 { grid-area: 1 / 6 / 2 / 7; }
.parent-13 .div7 { grid-area: 2 / 1 / 3 / 2; }
.parent-13 .div8 { grid-area: 2 / 2 / 3 / 3; }
.parent-13 .div9 { grid-area: 2 / 3 / 3 / 4; }
.parent-13 .div10 { grid-area: 2 / 4 / 3 / 5; }
.parent-13 .div11 { grid-area: 2 / 5 / 3 / 6; }
.parent-13 .div12 { grid-area: 2 / 6 / 3 / 7; }
.parent-13 .div13 { grid-area: 3 / 1 / 4 / 2; }
.parent-13 .div14 { grid-area: 3 / 2 / 4 / 3; }
.parent-13 .div15 { grid-area: 3 / 3 / 4 / 4; }
.parent-13 .div16 { grid-area: 3 / 4 / 4 / 5; }
.parent-13 .div17 { grid-area: 3 / 5 / 4 / 6; }
.parent-13 .div18 { grid-area: 3 / 6 / 4 / 7; }
.parent-13 .div19 { grid-area: 4 / 1 / 5 / 2; }
.parent-13 .div20 { grid-area: 4 / 2 / 5 / 3; }
.parent-13 .div21 { grid-area: 4 / 3 / 5 / 4; }
.parent-13 .div22 { grid-area: 4 / 4 / 5 / 5; }
.parent-13 .div23 { grid-area: 4 / 5 / 5 / 6; }
.parent-13 .div24 { grid-area: 4 / 6 / 5 / 7; }

.parent-14 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-14 .div1 { grid-area: 1 / 2 / 3 / 4; }
.parent-14 .div2 { grid-area: 1 / 1 / 2 / 2; }
.parent-14 .div3 { grid-area: 2 / 1 / 3 / 2; }
.parent-14 .div4 { grid-area: 3 / 1 / 4 / 2; }
.parent-14 .div5 { grid-area: 3 / 2 / 4 / 3; }
.parent-14 .div6 { grid-area: 3 / 3 / 4 / 4; }
.parent-14 .div7 { grid-area: 3 / 4 / 4 / 5; }
.parent-14 .div8 { grid-area: 2 / 4 / 3 / 5; }
.parent-14 .div9 { grid-area: 1 / 4 / 2 / 5; }

.parent-15 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-15 .div1 { grid-area: 1 / 1 / 3 / 3; }
.parent-15 .div2 { grid-area: 1 / 3 / 2 / 4; }
.parent-15 .div3 { grid-area: 1 / 4 / 2 / 5; }
.parent-15 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-15 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-15 .div6 { grid-area: 3 / 1 / 4 / 2; }
.parent-15 .div7 { grid-area: 3 / 2 / 4 / 3; }
.parent-15 .div8 { grid-area: 3 / 3 / 4 / 4; }
.parent-15 .div9 { grid-area: 3 / 4 / 4 / 5; }

.parent-16 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-16 .div1 { grid-area: 1 / 1 / 2 / 4; }
.parent-16 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-16 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-16 .div4 { grid-area: 2 / 3 / 3 / 4; }

.parent-17 {
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 4px;
grid-row-gap: 4px;
}

.parent-17 .div1 { grid-area: 1 / 1 / 2 / 4; }
.parent-17 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-17 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-17 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-17 .div5 { grid-area: 3 / 1 / 4 / 2; }
.parent-17 .div6 { grid-area: 3 / 2 / 4 / 3; }
.parent-17 .div7 { grid-area: 3 / 3 / 4 / 4; }

.parent-18 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-18 .div1 { grid-area: 1 / 1 / 2 / 5; }
.parent-18 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-18 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-18 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-18 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-18 .div6 { grid-area: 3 / 1 / 4 / 2; }
.parent-18 .div7 { grid-area: 3 / 2 / 4 / 3; }
.parent-18 .div8 { grid-area: 3 / 3 / 4 / 4; }
.parent-18 .div9 { grid-area: 3 / 4 / 4 / 5; }

.parent-19 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-19 .div1 { grid-area: 1 / 1 / 2 / 5; }
.parent-19 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-19 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-19 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-19 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-19 .div6 { grid-area: 3 / 1 / 4 / 2; }
.parent-19 .div7 { grid-area: 3 / 2 / 4 / 3; }
.parent-19 .div8 { grid-area: 3 / 3 / 4 / 4; }
.parent-19 .div9 { grid-area: 3 / 4 / 4 / 5; }
.parent-19 .div10 { grid-area: 4 / 1 / 5 / 2; }
.parent-19 .div11 { grid-area: 4 / 2 / 5 / 3; }
.parent-19 .div12 { grid-area: 4 / 3 / 5 / 4; }
.parent-19 .div13 { grid-area: 4 / 4 / 5 / 5; }

.parent-20 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-20 .div1 { grid-area: 1 / 1 / 2 / 6; }
.parent-20 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-20 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-20 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-20 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-20 .div6 { grid-area: 2 / 5 / 3 / 6; }
.parent-20 .div7 { grid-area: 3 / 1 / 4 / 2; }
.parent-20 .div8 { grid-area: 3 / 2 / 4 / 3; }
.parent-20 .div9 { grid-area: 3 / 3 / 4 / 4; }
.parent-20 .div10 { grid-area: 3 / 4 / 4 / 5; }
.parent-20 .div11 { grid-area: 3 / 5 / 4 / 6; }
.parent-20 .div12 { grid-area: 4 / 1 / 5 / 2; }
.parent-20 .div13 { grid-area: 4 / 2 / 5 / 3; }
.parent-20 .div14 { grid-area: 4 / 3 / 5 / 4; }
.parent-20 .div15 { grid-area: 4 / 4 / 5 / 5; }
.parent-20 .div16 { grid-area: 4 / 5 / 5 / 6; }
.parent-20 .div17 { grid-area: 5 / 1 / 6 / 2; }
.parent-20 .div18 { grid-area: 5 / 2 / 6 / 3; }
.parent-20 .div19 { grid-area: 5 / 3 / 6 / 4; }
.parent-20 .div20 { grid-area: 5 / 4 / 6 / 5; }
.parent-20 .div21 { grid-area: 5 / 5 / 6 / 6; }

</style>